<template>
    <section class="content">
        <table class="table table-hover" ref="tablesiswa">
            <thead>
                <tr>
                    <th>Foto</th>
                    <th>Nama</th>
                    <th>Gender</th>
                    <th>Birth date</th>
                    <th>Alamat</th>
                    <th>No ktp</th>
                    <th>Asal sekolah</th>
                    <th>Jurusan sekolah</th>
                    <th>Tanggal Pendaftaran</th>
                    <th>Tahun Ajaran</th>
                    <th>Program</th>
                    <th>Pembayaran</th>
                    <th>Pengajuan Angsuran</th>
                    <th>Approval Pengajuan</th>
                    <th>Total</th>
                    <th>Terbayar</th>
                    <th>Biaya Pendaftaran</th>
                    <th>Action</th>
                    <!-- <th>No hp</th> -->
                    <!-- <th>Kwitansi Pendaftaran</th> -->
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="card" style="width:100%">
                                  <div class="card-header d-flex p-0">
                                    <ul class="nav nav-pills mr-auto p-2">
                                      <li class="nav-item"><a class="nav-link active" href="#tab_1" data-toggle="tab">Umum</a></li>
                                      <li class="nav-item"><a class="nav-link" href="#tab_2" data-toggle="tab">Pendidikan</a></li>
                                      <li class="nav-item"><a class="nav-link" href="#tab_3" data-toggle="tab">Fisik</a></li>
                                      <li class="nav-item"><a class="nav-link" href="#tab_4" data-toggle="tab">Family</a></li>
                                      <li class="nav-item"><a class="nav-link" href="#tab_5" data-toggle="tab">Program</a></li>
                                    </ul>
                                  </div><!-- /.card-header -->
                                  <div class="card-body">
                                    <div class="tab-content">
                                      <div class="tab-pane active" id="tab_1">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Username</label>
                                                <input type="hidden" name="row_id" value="" />
                                                <input id="username" class="form-control" v-model="form.username" type="text" name="username" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Display Name</label>
                                                <input id="name" class="form-control" v-model="form.display_name" type="text" name="i_name" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Email</label>
                                                <input id="email" class="form-control" v-model="form.email" type="text" name="i_email" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Password</label>
                                                <div class="input-group mb-3">
                                                    <input id="pwd" class="form-control" value="" type="password" name="i_pwd" v-model="form.password" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" v-on:click="togglePass"><i class="far fa-eye" style="pointer-events:none"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Gender</label>
                                                <select v-model="form.gender" class="form-control">
                                                    <option v-for="item in genders" v-bind:value="item.id">{{ item.label }}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label for="inputFile">Foto Siswa</label>
                                                <input type="hidden" name="img_code" value="" v-model="form.img_code" id="img_code" />
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFotoProfile(this)">
                                                    <label class="custom-file-label" for="inputFile" ref="textFotoSiswa">Choose file</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Birth date</label>
                                                <datepicker v-model="form.birth_date" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Birth Place</label>
                                                <input id="birth_place" class="form-control" v-model="form.birth_place" type="text" name="i_birth_place" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Alamat</label>
                                                <input id="alamat" class="form-control" v-model="form.alamat" type="text" name="i_alamat" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No KTP</label>
                                                <input id="no_ktp" class="form-control" v-model="form.no_ktp" type="text" name="i_no_ktp" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No KK</label>
                                                <input id="no_kk" class="form-control" v-model="form.no_kk" type="text" name="i_no_kk" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No HP</label>
                                                <input id="no_hp" class="form-control" v-model="form.no_hp" type="number" name="i_no_hp" />
                                            </div>
                                        </div>
                                      </div>
                                      <!-- /.tab-pane -->
                                      <div class="tab-pane" id="tab_2">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Asal Sekolah</label>
                                                <input id="asal_sekolah" class="form-control" v-model="form.asal_sekolah" type="text" name="i_asal_sekolah" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Jurusan Sekolah</label>
                                                <input id="jurusan_sekolah" class="form-control" v-model="form.jurusan_sekolah" type="text" name="i_jurusan_sekolah" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Lulus Sekolah</label>
                                                <datepicker v-model="form.lulus_sekolah" :options="{format:'yyyy', startView: 2,minViewMode: 2}" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Kuliah</label>
                                                <input id="kuliah" class="form-control" v-model="form.kuliah" type="text" name="i_kuliah" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fakultas</label>
                                                <input id="fakultas" class="form-control" v-model="form.fakultas" type="text" name="i_fakultas" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Jurusan</label>
                                                <input id="jurusan" class="form-control" v-model="form.jurusan" type="text" name="i_jurusan" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Semester</label>
                                                <input id="semester" class="form-control" v-model="form.semester" type="text=" name="i_semester" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Tujuan Dinas</label>
                                                <!-- <input id="tujuan_dinas" class="form-control" v-model="form.tujuan_dinas" type="text" name="i_tujuan_dinas" /> -->
                                                <v-select :options="optDinas" v-model="form.tujuan_dinas" :reduce="opt => opt.label" />
                                            </div>
                                        </div>
                                      </div>
                                      <!-- /.tab-pane -->
                                      <div class="tab-pane" id="tab_3">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fisik Tinggi</label>
                                                <input id="fisik_tinggi" class="form-control" v-model="form.fisik_tinggi" type="text" name="i_fisik_tinggi" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fisik Berat</label>
                                                <input id="fisik_berat" class="form-control" v-model="form.fisik_berat" type="text=" name="i_fisik_berat" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fisik Mata</label>
                                                <input id="fisik_mata" class="form-control" v-model="form.fisik_mata" type="text" name="i_fisik_mata" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fisik Kacamata</label>
                                                <input id="fisik_kacamata" class="form-control" v-model="form.fisik_kacamata" type="text" name="i_fisik_kacamata" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fisik Kelainan</label>
                                                <input id="fisik_kelainan" class="form-control" v-model="form.fisik_kelainan" type="text" name="i_fisik_kelainan" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Fisik Penyakit</label>
                                                <input id="fisik_penyakit" class="form-control" v-model="form.fisik_penyakit" type="text" name="i_fisik_penyakit" />
                                            </div>
                                        </div>
                                      </div>
                                      <div class="tab-pane" id="tab_4">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Nama Ayah</label>
                                                <input id="nama_ayah" class="form-control" v-model="form.nama_ayah" type="text" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Nama Ibu</label>
                                                <input id="nama_ibu" class="form-control" v-model="form.nama_ibu" type="text" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Pekerjaan Ayah</label>
                                                <input id="pekerjaan_ayah" class="form-control" v-model="form.pekerjaan_ayah" type="text" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Pekerjaan Ibu</label>
                                                <input id="pekerjaan_ibu" class="form-control" v-model="form.pekerjaan_ibu" type="text" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No. Whatsapp Ayah</label>
                                                <input id="notelp_ayah" class="form-control" v-model="form.notelp_ayah" type="text" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No. Whatsapp Ibu</label>
                                                <input id="notelp_ibu" class="form-control" v-model="form.notelp_ibu" type="text" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label"> </label>
                                                <label class="checkbox control-label">
                                                    <input 
                                                        type="checkbox" 
                                                        name="active" 
                                                        v-model="form.status"
                                                        true-value="1"
                                                        false-value="0"
                                                        />
                                                 Active</label>
                                            </div>
                                        </div>
                                      </div>
                                      <div class="tab-pane" id="tab_5">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Tanggal Pendaftaran</label>
                                                <datepicker v-model="form.tanggal_pendaftaran" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Tahun Ajaran</label>
                                                <VueDatePicker v-model="form.tahun_ajaran" type="year" placeholder="Tahun Ajaran"/>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Uang Pendaftaran</label>
                                                <currency-input id="pendaftaran" class="form-control" currency="IDR" v-model="form.uang_pendaftaran" :precision="0" name="pendaftaran" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Cabang</label>
                                                <select v-model="form.branch_id" class="form-control">
                                                    <option v-for="item in optCabang" v-bind:value="item.id">{{ item.label }}</option>
                                                </select>
                                            </div>
                                <div class="col-md-12">
                                            <label class="control-label">Angsuran Biaya Pendaftaran</label>
                                    <div class="tbl">
                                        <button type="button" class="btn btn-primary add" v-on:click="addItem2" style="margin-bottom: 12px;">Tambah item</button>
                                        <table class="table table-hover" ref="tblpendaftaran" id="tblform4">
                                            <thead id="tblform5">
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>Nominal</th>
                                                    <th>No. Kwitansi</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tblform6">
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                            <!-- <div class="col-md-6 form-group">
                                                <label class="control-label">Nomor Kwitansi</label>
                                                <input id="nomor_kwitansi" class="form-control" v-model="form.nomor_kwitansi" type="text" />
                                            </div> -->
                                            <div class="col-md-12 form-group">
                                                <label class="control-label">Pilih program</label>
                                                <v-select :options="optProgram" v-model="form.program_id" :reduce="opt => opt.id" @input="calculate"/>
                                            </div>
                                            
                                            <div class="col-sm-12">
                                                <div style="width: 100%;height: auto;background-color: #9D9D9D;color: white;font-weight: 500;font-size: initial;padding: 20px;border-radius: 4px;margin-bottom: 12px;">
                                                    Biaya : 
																										<!-- <input id="family_job" class="form-control" v-model="form.nilai_total" type="text" name="i_family_job" /> -->
																										<currency-input id="family_job" class="form-control" currency="IDR" v-model="form.nilai_total" :precision="0" name="family_job" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 form-group">
                                                <label class="control-label"> </label>
                                                <label class="checkbox control-label">
                                                    <input 
                                                        type="checkbox" 
                                                        name="active" 
                                                        v-model="form.is_pengajuan_angsuran"
                                                        true-value="t"
                                                        false-value="f"
                                                        />
                                                 Pengajuan Angsuran</label>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="tbl">
                                                    <button type="button" class="btn btn-primary add" v-on:click="addItem" style="margin-bottom: 12px;">Tambah item</button>
                                                    <table class="table table-hover" ref="tableuser" id="tblform">
                                                        <thead id="tblform2">
                                                            <tr>
                                                                <th>Tanggal</th>
                                                                <th>Nominal</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="tblform3">
                                                        </tbody>
                                                      </table>
                                                </div>
                                            </div>
                                            <div class="form-group mt-5" v-if="form.is_pengajuan_angsuran == '1'">
                                                <label for="keterangan">Alasan</label>
                                                <textarea
                                                  class="form-control"
                                                  id="keterangan"
                                                  rows="3"
                                                  v-model="form.keterangan_angsuran"
                                                  placeholder="Tuliskan alasan untuk pengajuan angsuran pembayaran"
                                                ></textarea>
                                            </div>
                                        </div>
                                      </div>
                                      <!-- /.tab-pane -->
                                    </div>
                                    <!-- /.tab-content -->
                                  </div><!-- /.card-body -->
                                </div>
                                <!-- ./card -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Form Upload Kwitansi Biaya Program -->
        <div class="modal" tabindex="-1" role="dialog" ref="formUploadBukti" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form">
                        <div class="modal-header">
                            <h5 class="modal-title">Upload Bukti Pembayaran Program</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div style="width: 100%;height: auto;background-color: #9D9D9D;color: white;font-weight: 500;font-size: initial;padding: 20px;border-radius: 4px;margin-bottom: 12px;">
                                <div v-if="formUpload.kekurangan < 1">
                                  <h5>SUDAH LUNAS</h5>
                                </div>
                                <div v-if="formUpload.kekurangan > 0">
                                  Kurang bayar : Rp {{formatMoney(formUpload.kekurangan)}} <button type="button" class="btn btn-primary" style="float: right;" v-on:click="lunasi">Lunasi</button>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nominal</label>
                                <!-- <input id="nominal" class="form-control" v-model="formUpload.nominal" type="text" name="i_nominal" ref="nominal"/> -->
                                <currency-input id="nominal" class="form-control" currency="IDR" v-model="formUpload.nominal" :precision="0" name="i_nominal" ref="nominal" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nomor Kwitansi</label>
                                <input id="kwitansi" class="form-control" v-model="formUpload.no_kwitansi" type="text" name="i_no_kwitansi" ref="no_kwitansi"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Bukti</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfileBayar" id="inputFileBayar" name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFileBayar" ref="imgname">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-12 form-group img-payment row" @click="handleClick">

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" ref="btnUploadBuktiDaftar" @click="submitUploadBukti" class="btn btn-primary submit-payment"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Sedang menyimpan...":"Save changes" }}</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Form Upload Kwitansi Biaya Pendaftaran -->
        <div class="modal" tabindex="-1" role="dialog" ref="formUploadKwitansi" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form">
                        <div class="modal-header">
                            <h5 class="modal-title">Upload Bukti Pembayaran Pendaftaran</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div style="width: 100%;height: auto;background-color: #9D9D9D;color: white;font-weight: 500;font-size: initial;padding: 20px;border-radius: 4px;margin-bottom: 12px;">
                                <div v-if="formUploadKwitansi.kekurangan < 1">
                                  <h5>SUDAH LUNAS</h5>
                                </div>
                                <div v-if="formUploadKwitansi.kekurangan > 0">
                                  Kurang bayar : Rp {{formatMoney(formUploadKwitansi.kekurangan)}} <button type="button" class="btn btn-primary" style="float: right;" v-on:click="lunasi2">Lunasi</button>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nominal</label>
                                <currency-input id="nominal" class="form-control" currency="IDR" v-model="formUploadKwitansi.nominal" :precision="0" name="i_nominal" ref="nominal" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nomor Kwitansi</label>
                                <input id="kwitansi" class="form-control" v-model="formUploadKwitansi.no_kwitansi" type="text" name="i_no_kwitansi" ref="no_kwitansi"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Bukti</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfileBayar2" id="inputFileBayar2" name="file" @change="uploadKwitansi(this)">
                                    <label class="custom-file-label" for="inputFileBayar2" ref="imgname2">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-12 form-group img-payment-kwitansi row" @click="handleClick">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" ref="btnUploadBuktiKwitansi" @click="submitUploadBuktiKwitansi" class="btn btn-primary submit-payment-kwitansi"><span v-if="loading2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading2 ? " Sedang menyimpan..." : "Save changes" }}</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, formatCurrency } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import { CurrencyInput } from "vue-currency-input";
import moment from "moment";

let idr = Intl.NumberFormat("en-ID", {
    style: "currency",
    currency: "IDR",
    useGrouping: true,
    maximumSignificantDigits: 3,
});

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            loading: false,
            loading2: false,
            groups: [],
            optProgram: [],
            optDinas: [],
            optProgramDetail: [],
            optCabang: [],
            genders: [
                {id: 'L', label: 'Laki-laki'},
                {id: 'P', label: 'Perempuan'}
            ],
            formTitle: 'Tambah User',
            formUpload: {
                id: '',
                code: '',
                nominal: 0,
                nilai: 0,
                pembayaran: 0,
                kekurangan: 0,
                no_kwitansi: ''
            },
            formUploadKwitansi: {
                id: '',
                code: '',
                nominal: 0,
                nilai: 0,
                pembayaran: 0,
                kekurangan: 0,
                no_kwitansi: ''
            },
            formDoUpload: {
                id: '',
                code: '',
                nominal: '',
            },
            formDoUpload2: {
                id: '',
                code: '',
                nominal: '',
            },
            form: {
                username : '',
                role_id : '',
                display_name : '',
                email : '',
                gender : '',
                birth_date : '',
                birth_place : '',
                alamat : '',
                no_ktp : '',
                no_kk : '',
                no_hp : '',
                asal_sekolah : '',
                jurusan_sekolah : '',
                lulus_sekolah : '',
                kuliah : '',
                fakultas : '',
                jurusan : '',
                semester : '',
                tujuan_dinas : '',
                fisik_tinggi : '',
                fisik_berat : '',
                fisik_mata : '',
                fisik_kacamata : '',
                fisik_kelainan : '',
                fisik_penyakit : '',
                relation_type : '',
                family_name : '',
                family_gender : '',
                family_birth_date : '',
                family_birth_place : '',
                family_education : '',
                family_job : '',
                status: '',
                program_id: '',
                id:'',
                nominal_angsuran: 0,
                jumlah_angsuran: 0,
                keterangan_angsuran: '',
                nilai_total: 0,
                img_code: '',
                nomor_kwitansi: '',
                uang_pendaftaran: 0,
                branch_id : 0
            },
            formItem: {
                tanggal: '',
                pembayaran: '',
            },
            formItem2: {
                waktu2: '',
                nominal2: '',
                no_kwitansi: '',
            },
            id_regis: '',
        }
    },
    components: {
        datepicker,
        vSelect,
        CurrencyInput,
        VueDatePicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        authFetch('/akademik/siswa_monitoring/dinas')
        .then(res => {
            if (res.status === 201) {
            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {
            this.optDinas = js.data;
        });
        authFetch('/masterdata/siswa_register/groups')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.groups = js.data;
            })
        authFetch('/masterdata/siswa_register/program')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optProgram = js.data;
            })
        authFetch('/masterdata/siswa_register/program_detail')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optProgramDetail = js.data;
            })
        authFetch('/management/inventaris/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optCabang = js.data;
            })
    },
    methods: {
        formatMoney(data){
            return formatCurrency(data);
        },
        handleClick(e) {
            if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
                this.getuploadBukti(e.srcElement.getAttribute('data-id'), e.srcElement.getAttribute('data-nilai'), e.srcElement.getAttribute('data-pembayaran'))
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-warning') {
                this.getuploadBuktiKwitansi(e.srcElement.getAttribute('data-id'), e.srcElement.getAttribute('data-nilai'), e.srcElement.getAttribute('data-pembayaran'))
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-success') {
                this.cetakInvoice(e.srcElement.getAttribute('data-id'))
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-info') {
                this.setujuiSiswa(e.srcElement.getAttribute('data-id'), e.srcElement.getAttribute('data-nilai'), e.srcElement.getAttribute('data-pembayaran'))
            }
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.srcElement.getAttribute('class') == 'svg-inline--fa fa-trash-alt fa-w-14 fa-2x') {
                this.removePembayaran(e.srcElement.getAttribute('data-id'))
            }
            if (e.srcElement.getAttribute('class') == 'svg-inline--fa fa-trash-alt fa-w-14 fa-2x kwitansi') {
                this.removePembayaran2(e.srcElement.getAttribute('data-id'))
            }
        },
        calculate: function(program) {
            // this.form.nilai_total = idr.format(this.optProgramDetail[program])
            this.form.nilai_total = parseFloat(this.optProgramDetail[program].replace('.00',''));
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var self = this;
            var items = [];
            var items2 = [];
            var file_data = $('#inputFile').prop('files');

            this.tbl
                .api()
                .rows()
                .every(function (rowIdx, tableLoop, rowLoop) {
                  var d = this.data();
                  items.push(d);
                });

            this.tblpendaftaran
                .api()
                .rows()
                .every(function (rowIdx, tableLoop, rowLoop) {
                  var d = this.data();
                  items2.push(d);
                });

            var allData = { group: self.form, items: items };

            let dataItem = '';
            items.map(function(val, key){
                Object.keys(val)
                .map(
                  (key) =>
                    dataItem += encodeURIComponent(key) + "[]=" + encodeURIComponent(val[key]) + '&'
                );
              })

            let dataItem2 = '';
            items2.map(function(val, key){
                Object.keys(val)
                .map(
                  (key) =>
                    dataItem2 += encodeURIComponent(key) + "[]=" + encodeURIComponent(val[key]) + '&'
                );
              })

            if (file_data.length == 0) {
                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                var urlSubmit = '/masterdata/siswa_register';

                if (this.method == 'PUT') urlSubmit = '/masterdata/siswa_register/' + this.form.id;
                authFetch(urlSubmit, {
                        method: this.method,
                        body: data+'&'+dataItem+'&'+dataItem2,
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                                for (var key in js) {
                                    if (js.hasOwnProperty(key)) {
                                        this.errors.push(js[key])
                                    }
                                }
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Informasi',
                                    text: 'Data tidak tersimpan!'
                                })

                                return false;
                            }
                        this.table.api().ajax.reload();
                        this.tbl.api().ajax.reload();
                        this.tblpendaftaran.api().ajax.reload();
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data tersimpan!'
                        })
                        $(e.formDialog).modal('hide');
                    });
            } else {
                this.doUploadProfile().then((res) => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}

                    return res.json();
                    
                }).then(js => {
                    this.errors = [];
                    if (!js.status) {
                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return false;
                    } else {
                        if (js.message[0]) {
                            self.form.img_code = js.message[0].code;
                        }

                        var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                        var urlSubmit = '/masterdata/siswa_register';

                        if (this.method == 'PUT') urlSubmit = '/masterdata/siswa_register/' + this.form.id;
                        authFetch(urlSubmit, {
                                method: this.method,
                                body: data+'&'+dataItem,
                            })
                            .then(res => {
                                if (res.status === 201) {

                                } else if (res.status === 400) {}
                                return res.json();
                            })
                            .then(js => {

                                this.errors = [];
                                if (!js.success) {
                                        for (var key in js) {
                                            if (js.hasOwnProperty(key)) {
                                                this.errors.push(js[key])
                                            }
                                        }
                                        Swal.fire({
                                            icon: 'info',
                                            title: 'Informasi',
                                            text: 'Data tidak tersimpan!'
                                        })

                                        return false;
                                    }
                                this.table.api().ajax.reload();
                                this.tbl.api().ajax.reload();
                                this.tblpendaftaran.api().ajax.reload();
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Informasi',
                                    text: 'Data tersimpan!'
                                })
                                $(e.formDialog).modal('hide');
                            });
                    }
                });
            }

            ev.preventDefault();
        },
        setujuiSiswa: function(id, nilai, pembayaran) {
            if (pembayaran > 0) {// == pembayaran
                authFetch('/masterdata/siswa_register/approve', {
                        method: 'POST',
                        body: 'status=1&id='+id
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                                for (var key in js) {
                                    if (js.hasOwnProperty(key)) {
                                        this.errors.push(js[key])
                                    }
                                }

                                return;
                            }
                        this.table.api().ajax.reload();
                        this.tbl.api().ajax.reload();
                        this.tblpendaftaran.api().ajax.reload();
                    });
            } else {
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Tagihan siswa belum lunas!'
                })
            }
        },
        //get upload data biaya program
        getuploadBukti: function(id, nilai, pembayaran) {
            const e = this.$refs;
            this.formUpload.id = id
            this.formUpload.nominal = 0
            this.formUpload.no_kwitansi = ''
            this.formUpload.nilai = nilai && nilai != 'NaN' ? nilai : 0
            this.formUpload.pembayaran = pembayaran && pembayaran != 'NaN' ? pembayaran : 0
            if (isNaN(this.formUpload.pembayaran)) {
                this.formUpload.pembayaran = 0
            }
            if (isNaN(pembayaran)) {
                this.formUpload.kekurangan = parseFloat(nilai) - 0
            } else {
                this.formUpload.kekurangan = parseFloat(nilai) - parseFloat(pembayaran)
            }
            authFetch('/masterdata/siswa_register/data_payment/'+id, {
                    method: 'GET',
                }).then((res) => {
                    this.loading = false;
                    if (res.status === 201) {} else if (res.status === 400) {}
                    return res.json();
                }).then(js => {
                    $('.img-payment').html('')
                    if (js.data) {
                        Object.keys(js.data).map(function(key, index) {
                            var kwt = js.data[index].no_kwitansi ? js.data[index].no_kwitansi : '';
                            $('.img-payment').append('<div class="col-sm-4" style="padding:6px"><a href="'+window.$apiUrl+'/uploads'+js.data[index].file_path+'/'+js.data[index].file_name+'" target="_blank"><img width="150px;" src="'+window.$apiUrl+'/uploads'+js.data[index].file_path+'/'+js.data[index].file_name+'"><br><div style="width:100%;text-align:center;margin-top:12px"><a><span>Kwitansi '+kwt+' <i style="margin-left:5px" class="far fa-trash-alt fa-2x" data-id="'+js.data[index].id+'"></i></span></div></div>')
                        });
                    }
                })
            
            if (this.formUpload.kekurangan < 1) {
              $(e.btnUploadBuktiDaftar).hide();
            } else {
              $(e.btnUploadBuktiDaftar).show();
            }
            $(e.imgname).html('Choose file');
            $(e.formUploadBukti).modal('show');
        },
        //get upload data biaya pendaftaran
        getuploadBuktiKwitansi: function(id, nilai, pembayaran) {
            const e = this.$refs;
            this.formUploadKwitansi.id = id
            this.formUploadKwitansi.nominal = 0
            this.formUploadKwitansi.no_kwitansi = ''
            this.formUploadKwitansi.nilai = nilai && nilai != 'NaN' ? nilai : 0
            this.formUploadKwitansi.pembayaran = pembayaran && pembayaran != 'NaN' ? pembayaran : 0
            if (isNaN(this.formUploadKwitansi.pembayaran)) {
                this.formUploadKwitansi.pembayaran = 0
            }
            if (isNaN(pembayaran)) {
                this.formUploadKwitansi.kekurangan = parseFloat(nilai) - 0
            } else {
                this.formUploadKwitansi.kekurangan = parseFloat(nilai) - parseFloat(pembayaran)
            }
            authFetch('/masterdata/siswa_register/data_kwitansi/'+id, {
                    method: 'GET',
                }).then((res) => {
                    this.loading2 = false;
                    if (res.status === 201) {} else if (res.status === 400) {}
                    return res.json();
                }).then(js => {
                    $('.img-payment-kwitansi').html('');
                    if (js.data) {
                        Object.keys(js.data).map(function(key, index) {
                            var kwt = js.data[index].no_kwitansi ? js.data[index].no_kwitansi : '';
                            $('.img-payment-kwitansi').append('<div class="col-sm-4" style="padding:6px"><a href="'+window.$apiUrl+'/uploads'+js.data[index].file_path+'/'+js.data[index].file_name+'" target="_blank"><img width="150px;" src="'+window.$apiUrl+'/uploads'+js.data[index].file_path+'/'+js.data[index].file_name+'"><br><div style="width:100%;text-align:center;margin-top:12px"><a><span>Kwitansi '+kwt+' <i style="margin-left:5px" class="far fa-trash-alt fa-2x kwitansi" data-id="'+js.data[index].id+'"></i></span></div></div>')
                        });
                    }
                })
            
            if (this.formUploadKwitansi.kekurangan < 1) {
              $(e.btnUploadBuktiKwitansi).hide();
            } else {
              $(e.btnUploadBuktiKwitansi).show();
            }
            $(e.imgname2).html('Choose file');
            $(e.formUploadKwitansi).modal('show');
        },
        //lunas bukti biaya program
        lunasi : function() {
            this.formUpload.nominal = this.formUpload.kekurangan
        },
        //lunas bukti biaya pendaftaran
        lunasi2 : function() {
            this.formUploadKwitansi.nominal = this.formUploadKwitansi.kekurangan
        },
        cetakInvoice: function(id) {

        },
        uploadFoto : function (file) {
            var self = this;
            const e = this.$refs;
            var file_data = $('#inputFileBayar').prop('files');
            var formUpload = new FormData();
            var fileInput = $('#inputFileBayar')[0];
            $.each(fileInput.files, function(k,file){
              formUpload.append('file', file);
              $(e.imgname).html(file.name);
            });

            formUpload.append('subdir', '/pembayaran/'+self.formUpload.id);
            formUpload.append('id', self.formUpload.id);
            formUpload.append('nominal', self.formUpload.nominal);
            formUpload.append('no_kwitansi', self.formUpload.no_kwitansi);
            self.formDoUpload = formUpload
        },
        uploadKwitansi : function (file) {
            var self = this;
            const e = this.$refs;
            var file_data = $('#inputFileBayar2').prop('files');
            var formUpload = new FormData();
            var fileInput = $('#inputFileBayar2')[0];
            $.each(fileInput.files, function(k,file){
              formUpload.append('file', file);
              $(e.imgname2).html(file.name);
            });
            formUpload.append('subdir', '/kwitansi/'+self.formUploadKwitansi.id);
            formUpload.append('id', self.formUploadKwitansi.id);
            formUpload.append('nominal', self.formUploadKwitansi.nominal);
            formUpload.append('no_kwitansi', self.formUploadKwitansi.no_kwitansi);
            self.formDoUpload2 = formUpload
        },
        //submit upload bukti biaya program
        submitUploadBukti: function() {
            var self = this;
            this.loading = true;
            $('.submit-payment').attr('disabled','disabled')

            self.formDoUpload.append('no_kwitansi', self.formUpload.no_kwitansi);
            self.formDoUpload.append('nominal', self.formUpload.nominal);

            var file_data = $('#inputFileBayar').prop('files');
            if (file_data.length == 0) {
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Mohon upload bukti transfer!'
                })
                $('.submit-payment').removeAttr('disabled')
                this.loading = false;
                return false;
            }
            const e = this.$refs;
            if (this.formUpload.nominal > this.formUpload.kekurangan) {
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Jumlah melebihi batas nominal pembayaran.'
                })
                this.loading = false;
                $('.submit-payment').removeAttr('disabled')
            } else {
                authFetch('/masterdata/siswa_register/upload_data', {
                    method: 'POST',
                    body: this.formDoUpload,
                    headers: {
                      'Content-Type': null,
                    }
                }).then((res) => {
                    this.loading = false;
                    if (res.status === 201) {

                    } else if (res.status === 400) {}

                    return res.json();
                    
                }).then(js => {
                    this.errors = [];
                        if (!js.status) {
                            for (var key in js) {
                                if (js.hasOwnProperty(key)) {
                                    this.errors.push(js[key])
                                }
                            }

                            return;
                        } else {
                            $(e.formUploadBukti).modal('hide');
                            this.table.api().ajax.reload();
                            Swal.fire({
                                icon: 'success',
                                title: 'Informasi',
                                text: 'Data telah tersimpan.'
                            })
                            $('.submit-payment').removeAttr('disabled')
                            this.formUpload.id =  ''
                            this.formUpload.code =  ''
                            this.formUpload.nominal =  0
                            this.formUpload.nilai =  0
                            this.formUpload.pembayaran =  0
                            this.formUpload.kekurangan =  0
                            this.formUpload.no_kwitansi =  ''
                        }
                })
            }
        },
        //submit upload bukti biaya pendaftaran
        submitUploadBuktiKwitansi: function() {
            var self = this;
            this.loading2 = true;
            $('.submit-payment-kwitansi').attr('disabled','disabled');

            self.formDoUpload2.append('no_kwitansi', self.formUploadKwitansi.no_kwitansi);
            self.formDoUpload2.append('nominal', self.formUploadKwitansi.nominal);
            var file_data = $('#inputFileBayar2').prop('files');
            if (file_data.length == 0) {
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Mohon upload bukti transfer!'
                })
                $('.submit-payment-kwitansi').removeAttr('disabled')
                this.loading2 = false;
                return false;
            }
            const e = this.$refs;
            if (this.formUploadKwitansi.nominal > this.formUploadKwitansi.kekurangan) {
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Jumlah melebihi batas nominal pembayaran.'
                });
                this.loading2 = false;
                $('.submit-payment-kwitansi').removeAttr('disabled');
            } else {
                authFetch('/masterdata/siswa_register/upload_kwitansi', {
                    method: 'POST',
                    body: this.formDoUpload2,
                    headers: {
                        'Content-Type': null,
                    }
                }).then((res) => {
                    this.loading2 = false;
                    if (res.status === 201) {
                    } else if (res.status === 400) {}
                    return res.json();
                }).then(js => {
                    this.errors = [];
                        if (!js.status) {
                            for (var key in js) {
                                if (js.hasOwnProperty(key)) {
                                    this.errors.push(js[key])
                                }
                            }
                            return;
                        } else {
                            $(e.formUploadKwitansi).modal('hide');
                            this.table.api().ajax.reload();
                            Swal.fire({
                                icon: 'success',
                                title: 'Informasi',
                                text: 'Data telah tersimpan.'
                            })
                            $('.submit-payment-kwitansi').removeAttr('disabled');
                            this.formUploadKwitansi.id =  ''
                            this.formUploadKwitansi.code =  ''
                            this.formUploadKwitansi.nominal =  0
                            this.formUploadKwitansi.nilai =  0
                            this.formUploadKwitansi.pembayaran =  0
                            this.formUploadKwitansi.kekurangan =  0
                            this.formUploadKwitansi.no_kwitansi =  ''
                        }
                })
            }
        },
        addItem: function(ev) {
            var nRow = this.tbl.fnAddRow(this.formItem);
            var aData = {
              tanggal: this.formItem.tanggal,
              pembayaran: this.formItem.pembayaran,
            };
            this.tbl.fnUpdate(aData, nRow);
            $('td .btn.edit', nRow).click();
            ev.preventDefault();
        },
        addItem2: function(ev) {
            var nRow = this.tblpendaftaran.fnAddRow(this.formItem2);
            var aData = {
                waktu2: this.formItem2.waktu2,
                nominal2: this.formItem2.nominal2,
                no_kwitansi: this.formItem2.no_kwitansi,
            };
            this.tblpendaftaran.fnUpdate(aData, nRow);
            $('td .btn.edit', nRow).click();
            ev.preventDefault();
        },
        uploadFotoProfile : function (file) {
            const e = this.$refs;
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
              $(e.textFotoSiswa).html(file.name);
            });
            form_data.append('subdir', '/registrasi/');
            // form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUploadProfile: function() {
            return authFetch('/management/cabang/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                'Content-Type': null,
                }
            })
        },
        downloadKwitansi: function(code)
        {
            this.loadingDownload = true;
            authFetch("/masterdata/siswa_register/download_kwitansi/"+code, {
                method: 'GET',
                headers: {
                'Content-Type': null,
                }
            })
            .then(response => response.blob())
            .then(blob => {
                this.loadingDownload = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                // a.download = "template.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });

        },
        togglePass: function() {
            var type = $('#pwd').attr('type');
            if (type == 'password') {
                $('#pwd').attr('type', 'text');
            } else {
                $('#pwd').attr('type', 'password');
            }
        },
        //delete upload bukti biaya program
        removePembayaran: function(id) {
            const e = this.$refs;
            Swal.fire({
                title: "Hapus data?",
                text: "Data pembayaran ini akan dihapus. ingin tetap melanjutkan?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isDenied) {
                  authFetch('/masterdata/siswa_register/data_payment/'+id, {
                        method: 'DELETE',
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data pembayaran berhasil dihapus!'
                        })
                        $(e.formUploadBukti).modal('hide');
                        this.table.api().ajax.reload();
                        this.formUpload.id =  ''
                        this.formUpload.code =  ''
                        this.formUpload.nominal =  0
                        this.formUpload.nilai =  0
                        this.formUpload.pembayaran =  0
                        this.formUpload.kekurangan =  0
                        this.formUpload.no_kwitansi =  ''
                    });
                }
              });
        },
        //delete upload bukti biaya pendaftaran
        removePembayaran2: function(id) {
            const e = this.$refs;
            Swal.fire({
                title: "Hapus data?",
                text: "Data pembayaran ini akan dihapus. ingin tetap melanjutkan?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isDenied) {
                  authFetch('/masterdata/siswa_register/data_payment/'+id, {
                        method: 'DELETE',
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data pembayaran berhasil dihapus!'
                        })
                        $(e.formUploadKwitansi).modal('hide');
                        this.table.api().ajax.reload();
                        this.formUploadKwitansi.id =  ''
                        this.formUploadKwitansi.code =  ''
                        this.formUploadKwitansi.nominal =  0
                        this.formUploadKwitansi.nilai =  0
                        this.formUploadKwitansi.pembayaran =  0
                        this.formUploadKwitansi.kekurangan =  0
                        this.formUploadKwitansi.no_kwitansi =  ''
                    });
                }
              });
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesiswa, {
            "title": "List Data",
            "roles": this.$route.params.roles,
            "ajax": "/masterdata/siswa_register",
            scrollX: true,
            "order": [[ 0, "desc" ]],
            "columns": [
                { "data": "img_code",
                render: function (data, type, row, meta) {
                    let img = '<img width="50px;" src="' +
                            window.$apiUrl +
                            "/uploads" +
                            row.file_path +
                            row.file_name +
                            '" v-if="todo.file_path">';
                    if (row.file_path) {
                        return img;
                    } else {
                        return '-';
                    }
                }},
                { "data": "display_name" },
                { "data": "gender" },
                { "data": "birth_date" },
                { "data": "addr_detail" },
                { "data": "no_ktp" },
                { "data": "asal_sekolah" },
                { "data": "jurusan_sekolah" },//7
                { "data": "tanggal_pendaftaran" },
                { "data": "tahun_ajaran" },
                { "data": "program_id" },
                { "data": "status", sortable: false },//pembayaran
                { "data": "is_pengajuan_angsuran", sortable: false },
                { "data": "approval_status", sortable: false },
                { "data": "nilai_total", sortable: false },
                { "data": "pembayaran", sortable: false },
                { "data": "uang_pendaftaran", sortable: false },
                { "data": "status" },
                { "data": "id", visible: false },
                { "data": "no_ktp", visible: false },
                // { "data": "nomor_kwitansi", sortable: false, visible: false },
            ],
            filterBy: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11],
            "rowCallback": function(row, data) {
                $('td:eq(17)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.siswa_registrasi_id+'" data-nilai="'+data.nilai_total+'" data-pembayaran="'+data.pembayaran+'">Upload Bukti Bayar</a><a type="button" class="btn btn-warning" data-id="'+data.siswa_registrasi_id+'" data-nilai="'+data.uang_pendaftaran+'" data-pembayaran="'+data.cicilan_pendaftaran+'">Upload Bukti Pendaftaran</a><a type="button" class="btn btn-success" data-id="'+data.siswa_registrasi_id+'">Cetak billing/invoice</a><a type="button" class="btn btn-info" data-id="'+data.siswa_registrasi_id+'" data-nilai="'+data.nilai_total+'" data-pembayaran="'+data.pembayaran+'">Complete Register</a>');
                if (data.program) {
                    $('td:eq(10)', row).html(data.program);
                    $('td:eq(13)', row).html("Rp "+formatCurrency(data.nilai_total));
                } else {
                    $('td:eq(10)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-warning" style="color:white">Belum memilih program</a>');
                    $('td:eq(13)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-warning" style="color:white">Belum memilih program</a>');
                }
                if (data.pembayaran == data.nilai_total && data.nilai_total > 0) {
                    $('td:eq(11)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-success" style="color:white">Lunas</a>');
                } else {
                    $('td:eq(11)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-warning" style="color:white">Belum Lunas</a>');
                }
                if (data.is_pengajuan_angsuran == 'f') {
                    $('td:eq(13)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-danger" style="color:white">Cash</a>');
                } else {
                    $('td:eq(13)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-success" style="color:white">Pengajuan</a>');
                }
                if (data.approval_status == '100') {
                    $('td:eq(12)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-danger" style="color:white">Reject</a>');
                } else if (data.approval_status == '1') {
                    $('td:eq(12)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-success" style="color:white">Approved</a>');
                } else {
                    if (data.is_pengajuan_angsuran == 'f') {
                        $('td:eq(12)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-info" style="color:white">Tanpa Approval</a>');
                    } else {
                        $('td:eq(12)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-warning" style="color:white">Waiting</a>');
                    }
                }
                $('td:eq(14)', row).html(idr.format(data.nilai_total));
                if (data.pembayaran > 0) {
                    $('td:eq(15)', row).html("Rp "+formatCurrency(data.pembayaran));
                } else {
                    $('td:eq(15)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-danger" style="color:white">Belum ada pembayaran</a>');
                }
                $('td:eq(16)', row).html("Rp "+formatCurrency(data.uang_pendaftaran));
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Registrasi Siswa';
                    $(e.formDialog).modal('show');
                    this.tbl.api().ajax.reload();
                    this.tblpendaftaran.api().ajax.reload();
                    $(e.textFotoSiswa).html('Choose file');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.form.uang_pendaftaran = parseFloat(evt.data.uang_pendaftaran);
                    self.form.nilai_total = parseFloat(evt.data.nilai_total);
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Data Registrasi';
                    self.id_regis = evt.data.siswa_registrasi_id;
                    $(e.formDialog).modal('show');
                    this.tbl.api().ajax.reload();
                    this.tblpendaftaran.api().ajax.reload();
                    $(e.textFotoSiswa).html('Choose file');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    Swal.fire({
                        title: "Hapus data?",
                        text: "Peringatan! semua data yang terhubung dengan siswa ini akan dihapus. ingin tetap melanjutkan?",
                        icon: "question",
                        denyButtonText: '<i class="fa fa-times"></i> Hapus',
                        showCancelButton: true,
                        showDenyButton: true,
                        showConfirmButton: false,
                      }).then((result) => {
                        if (result.isDenied) {
                          authFetch('/masterdata/siswa_register/' + evt.data.siswa_registrasi_id, {
                                method: 'DELETE',
                                body: 'id=' + evt.data.siswa_registrasi_id
                            })
                            .then(res => {
                                return res.json();
                            })
                            .then(js => {
                                this.table.api().ajax.reload();
                            });
                        }
                      });
                }

            },
        })

        this.tbl = createTable(e.tableuser, {
            title: "",
            serverSide: false,
            filter: false,
            ajax: "/masterdata/siswa_register/skemabayar",
            order: [[ 0, "desc" ]],
            paramData: function (d) {
                d.id_regis = self.id_regis;
            },
            lengthChange: false,
            paging: false,
            info: false, 
            columns: [
                { data: "tanggal", type: "date"},
                { data: "pembayaran",
                render: function (data, type, row, meta) {
                    return "Rp "+formatCurrency(data);
                } },
                { data: "action",
                sortable: false,
                render: function (data, row, type, meta) {
                  return '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>';
                } },
            ],
            rowCallback: function (row, data) {}
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
        });

        this.tblpendaftaran = createTable(e.tblpendaftaran, {
            title: "",
            serverSide: false,
            filter: false,
            ajax: "/masterdata/siswa_register/biaya_pendaftaran",
            order: [[ 0, "desc" ]],
            paramData: function (d) {
                d.id_regis = self.id_regis;
            },
            lengthChange: false,
            paging: false,
            info: false, 
            columns: [
                { data: "waktu2", type: "date"},
                { data: "nominal2",
                render: function (data, type, row, meta) {
                    return "Rp "+formatCurrency(data);
                } },
                { data: "no_kwitansi"},
                { data: "action",
                sortable: false,
                render: function (data, row, type, meta) {
                  return '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>';
                } },
            ],
            rowCallback: function (row, data) {}
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
        });
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
</style>